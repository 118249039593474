import ApiService from './ApiService'
import API from 'configs/apis'
import {constructUrlWithParams} from 'utils/utils'

export async function apiGetAnimals(data) {
    const result = await ApiService.fetchData({
        url: API.getAnimals,
        method: 'get',
        params: data
    })
    return result
}


export async function apiGetAnimalDetail(animalId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getAnimalDetails, {
            animal_id: animalId
        }),
        method: 'get',
    })
}

export async function apiGetAnimalDetailByTagId(data) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getAnimalDetailByTagId, {
            tagId: data.tagId
        }),
        method: 'get',
        params: data
    })
}


export async function apiAddAnimal(data) {
    return ApiService.fetchData({
        url: API.getAnimals,
        method: 'post',
        data
    })
}

export async function apiEditAnimal(data) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getAnimalDetails, {
            animal_id: data.id
        }),
        method: 'patch',
        data
    })
}

export async function apiDeleteAnimal(animalId) {
    return ApiService.fetchData({
        url: constructUrlWithParams(API.getAnimalDetails, {
            animal_id: animalId
        }),
        method: 'delete',
    })
}

export async function apiGetLitterSummary(data) {
    const result = await ApiService.fetchData({
        url: API.getLitterSummary,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetBreedingEvent(data) {
    const result = await ApiService.fetchData({
        url: API.getBreedingEvent,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetBreedingEventRecords(animalId) {
    const result = await ApiService.fetchData({
        url: constructUrlWithParams(API.getBreedingEventRecords, {
            animalId: animalId
        }),
        method: 'get',
    })
    return result
}

export async function apiGetDailyManagementList(data) {
    const result = await ApiService.fetchData({
        url: API.getDailyManagementList,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetDailyMonitorList(data) {
    const result = await ApiService.fetchData({
        url: API.getDailyMonitorList,
        method: 'get',
        params: data
    })
    return result
}

export async function apiAddDailyTasks(data) {
    return ApiService.fetchData({
        url: API.addDailyTasks,
        method: 'post',
        data
    })
}