import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { 
    toastError, 
} from 'utils/toast'
import { INITIAL_FILTER_TABLE_DATA, INITIAL_ANIMAL_FORM } from 'constants/api.constant'
import { 
    apiGetAnimalDetailByTagId, 
    apiGetAnimals, 
    apiGetDailyManagementList,
    apiGetDailyMonitorList 
} from 'services/AnimalService';
import { setOpenAnimalAddEdit } from './stateSlice';
import { apiGetAnimalAlertsV2 } from 'services/AlertsService';

const prefix = `XLM/animal`;

export const getAnimals = createAsyncThunk(
    `${prefix}/getAnimals`,
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiGetAnimals(data)
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getAnimalDetail = createAsyncThunk(
    `${prefix}/getAnimalDetail`,
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiGetAnimals(data)
            return response.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getAnimalDetailByTagId = createAsyncThunk(
    `${prefix}/apiGetAnimalDetailByTagId`,
    async (data, {dispatch, rejectWithValue  }) => {
        const { value } = data
        try {
            const response = await apiGetAnimalDetailByTagId(value)
            dispatch(setOpenAnimalAddEdit(true))
            return response.data
        } catch (error) {
            return rejectWithValue();
        }
    }
)

export const getAnimalAlerts = createAsyncThunk(
    `${prefix}/getAnimalAlerts`,
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiGetAnimalAlertsV2(data)
            return response.data
        } catch (error) {
            toastError()
            return rejectWithValue(error);
        }
    }
)

export const getManagementList = createAsyncThunk(
    `${prefix}/getManagementList`,
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiGetDailyManagementList(data)
            return response.data
        } catch (error) {
            toastError()
            return rejectWithValue(error);
        }
    }
)

export const getMonitorList = createAsyncThunk(
    `${prefix}/getMonitorList`,
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiGetDailyMonitorList(data)
            return response.data
        } catch (error) {
            toastError()
            return rejectWithValue(error);
        }
    }
)

const initialState = {
    // Room inventory
    loading: false,
    animalList: [],
    tableData: { 
        ...INITIAL_FILTER_TABLE_DATA,
        order_by: "-status",
        location_id: null,
        start_time: null,
        end_time: null,
        ignore_acted: false,
    },
    animalForm: {
        ...INITIAL_ANIMAL_FORM
    },
    // Alert list
    loadingAnimalAlerts: false,
    animalAlertList: [],
    tableDataAnimalAlerts: {
        ...INITIAL_FILTER_TABLE_DATA,
        order_by: "pen_name",
        location_id: null,
    },
    alertsLast24Hours: 0,
    // Sick list
    loadingManagementList: false,
    pigsAlertsLast24HourInDailyTasks: null,
    ManagementList: [],
    tableDataManagementList: {
        ...INITIAL_FILTER_TABLE_DATA,
        order_by: "pen_name",
        location_id: null
    },
    // Watch list
    loadingMonitorList: false,
    animalMonitorList: [],
    tableDataMonitorList: {
        ...INITIAL_FILTER_TABLE_DATA,
        order_by: "pen_name",
        location_id: null,
    },
    isChecked: true,
}

const dataSlice = createSlice({
    name: `${prefix}`,
    initialState,
    reducers: {
        resetAnimalManagementTable: () => initialState,
        updateAnimalList: (state, action) => {
            state.animalList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setAnimalForm: (state, action) => {
            state.animalForm = action.payload
        },
        setTableDataAnimalAlerts: (state, action) => {
            state.tableDataAnimalAlerts = action.payload
        },
        setTableDataManagementList: (state, action) => {
            state.tableDataManagementList = action.payload
        },
        setTableDataMonitorList: (state, action) => {
            state.tableDataMonitorList = action.payload
        },
        setIsChecked: (state, action) => {
            state.isChecked = action.payload
        },
    },
    extraReducers: {
        // Room inventory
        [getAnimals.fulfilled]: (state, action) => {
            const { result, count, limit, page } = action.payload
            if (result) {
                state.animalList = result
                state.tableData.count = count
                state.tableData.limit = limit
                state.tableData.page = page
            }
            state.loading = false
        },
        [getAnimals.pending]: (state) => {
            state.loading = true
        },
        [getAnimals.rejected]: (state) => {
            state.loading = false
            toastError()
        },
        [getAnimalDetailByTagId.fulfilled]: (state, action) => {
            state.animalForm = action.payload
        },
        // Alert list
        [getAnimalAlerts.fulfilled]: (state, action) => {
            const { result, count, limit, page, alerts_last_24_hours } = action.payload
            if (result) {
                state.animalAlertList = result
                state.tableDataAnimalAlerts.count = count
                state.tableDataAnimalAlerts.limit = limit
                state.tableDataAnimalAlerts.page = page
                state.alertsLast24Hours = alerts_last_24_hours
            }
            state.loadingAnimalAlerts = false
        },
        [getAnimalAlerts.pending]: (state) => {
            state.loadingAnimalAlerts = true
        },
        [getAnimalAlerts.rejected]: (state) => {
            state.loadingAnimalAlerts = false
        },
        // Sick list
        [getManagementList.fulfilled]: (state, action) => {
            const { result, count, limit, page, pigs_alerts_last_24_hours } = action.payload
            if (result) {
                state.ManagementList = result
                state.tableDataManagementList.count = count
                state.tableDataManagementList.limit = limit
                state.tableDataManagementList.page = page
                state.pigsAlertsLast24HourInDailyTasks = pigs_alerts_last_24_hours
            }
            state.loadingManagementList = false
        },
        [getManagementList.pending]: (state) => {
            state.loadingManagementList = true
        },
        [getManagementList.rejected]: (state) => {
            state.loadingManagementList = false
        },
        // Watch list
        [getMonitorList.fulfilled]: (state, action) => {
            const { result, count, limit, page } = action.payload
            if (result) {
                state.animalMonitorList = result
                state.tableDataMonitorList.count = count
                state.tableDataMonitorList.limit = limit
                state.tableDataMonitorList.page = page
            }
            state.loadingMonitorList = false
        },
        [getMonitorList.pending]: (state) => {
            state.loadingMonitorList = true
        },
        [getMonitorList.rejected]: (state) => {
            state.loadingMonitorList = false
        },
    },
})

export const {
    updateLocationList,
    setTableData,
    setAnimalForm,
    setTableDataAnimalAlerts,
    setTableDataManagementList,
    setTableDataMonitorList,
    setIsChecked,
    resetAnimalManagementTable,
} = dataSlice.actions

export default dataSlice.reducer
