import roomConfig from '../room.json'

export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const REQUEST_HEADER_DOMAIN = 'X-Tenant-Domain'
export const REQUEST_HEADER_IDENTIFIER = 'X-Tenant-Identifier'
export const MAX_FETCH_LIMIT = 999999999
export const MAX_INTENSITY = 50
export const CURRENT_ANIMAL_QUERY_INTERVAL = 1440 //minutes
export const TIMEZONE = "Australia/Perth"
export const ANIMAL_STATUS_NAME = {
    "POOR": "poor",
    "NORMAL": "normal",
    "SUB_OPTIMAL": "sub-optimal",
    "SUSPECTED_DEAD": "suspected_dead",
    "CONFIRMED_DEAD": "confirmed_dead",
    "INACTIVE": "inactive",
    "DETACHED": "detached",
    "COMPLETED": "completed",
    "UNSEEN": "unseen",
}
export const ANIMAL_HEALTH_STATUSES = [
    ANIMAL_STATUS_NAME.NORMAL,
    ANIMAL_STATUS_NAME.SUB_OPTIMAL,
    ANIMAL_STATUS_NAME.POOR
]
export const ANIMAL_STATUS_NAME_LABEL = {
    [ANIMAL_STATUS_NAME.POOR]: "global.txtPoor",
    [ANIMAL_STATUS_NAME.NORMAL]: "global.txtNormal",
    [ANIMAL_STATUS_NAME.SUB_OPTIMAL]: "global.txtSubOptimal",
    [ANIMAL_STATUS_NAME.SUSPECTED_DEAD]: "global.txtSuspectedDead",
    [ANIMAL_STATUS_NAME.CONFIRMED_DEAD]: "global.txtConfirmedDead",
    [ANIMAL_STATUS_NAME.INACTIVE]: "global.txtInactive",
    [ANIMAL_STATUS_NAME.DETACHED]: "global.txtDetached",
    [ANIMAL_STATUS_NAME.COMPLETED]: "global.txtCompleted",
    [ANIMAL_STATUS_NAME.UNSEEN]: "global.txtUnseen",

}
export const ANIMAL_STATUS_TEXT_COLOR = {
    [ANIMAL_STATUS_NAME.NORMAL]: "#22c55e",
    [ANIMAL_STATUS_NAME.POOR]: "#dc2626",
    [ANIMAL_STATUS_NAME.SUB_OPTIMAL]: "#f59e0b",
}
export const ANIMAL_STATUS_BG_COLOR = {
    [ANIMAL_STATUS_NAME.NORMAL]: "#00BD65",
    [ANIMAL_STATUS_NAME.POOR]: "#DF1A27",
    [ANIMAL_STATUS_NAME.SUB_OPTIMAL]: "#F9A525",
}
export const ANIMAL_STATUS_BORDER_COLOR = {
    [ANIMAL_STATUS_NAME.NORMAL]: "#4ade80",
    [ANIMAL_STATUS_NAME.POOR]: "#f87171",
    [ANIMAL_STATUS_NAME.SUB_OPTIMAL]: "#fbbf24",
}
export const BREEDING_STATUS = {
    "NOT_IN_HEAT": "not_in_heat",
    "SERVICED": "serviced",
    "IN_HEAT": "in_heat",
    "IN_PIG": "in_pig",
    "FARROWING": "farrowing",
    "NURSING": "nursing"
}
export const BREEDING_STATUS_LABEL = {
    [BREEDING_STATUS.NOT_IN_HEAT]: "global.txtNotInHeat",
    [BREEDING_STATUS.SERVICED]: "global.txtServiced",
    [BREEDING_STATUS.IN_HEAT]: "global.txtInHeat",
    [BREEDING_STATUS.IN_PIG]: "global.txtInPig",
    [BREEDING_STATUS.FARROWING]: "global.txtFarrowing",
    [BREEDING_STATUS.NURSING]: "global.txtNursing",
}
export const BREEDING_STATUS_COLOR = {
    [BREEDING_STATUS.NOT_IN_HEAT]: "#b87216",
    [BREEDING_STATUS.SERVICED]: "#b35888",
    [BREEDING_STATUS.IN_HEAT]: "#2785d7",
    [BREEDING_STATUS.IN_PIG]: "#ffb470",
    [BREEDING_STATUS.FARROWING]: "#FF33FF",
    [BREEDING_STATUS.NURSING]: "#0a01cc",
}
export const LOCATION_TYPE = {
    NURSERY_ROOM: "nursery_room",
    GROWER_FINISHER_ROOM: "grower_finisher_room",
    GILT: "gilt",
    SOW_DRY_ROOM: "sow_dry_room",
    SOW_FARROWING: "sow_farrowing",
    LOADING_SHED: "loading_shed",
    OVERFLOW_ROOM: "overflow_room",
    INFIRMARY_SHED: "infirmary_shed",
    TRANSPORT: "transoprt",
    GENERAL: "general",
}
export const LOCATION_TYPE_OPTIONS = [
    {
        label: "Nursery room",
        value: LOCATION_TYPE.NURSERY_ROOM
    },
    {
        label: "Grower finisher room",
        value: LOCATION_TYPE.GROWER_FINISHER_ROOM
    },
    {
        label: "Gilt",
        value: LOCATION_TYPE.GILT
    },
    {
        label: "Sow dry room",
        value: LOCATION_TYPE.SOW_DRY_ROOM
    },
    {
        label: "Sow farrowing",
        value: LOCATION_TYPE.SOW_FARROWING
    },
    {
        label: "Loading shed",
        value: LOCATION_TYPE.LOADING_SHED
    },
    {
        label: "Overflow room",
        value: LOCATION_TYPE.OVERFLOW_ROOM
    },
    {
        label: "Infirmary shed",
        value: LOCATION_TYPE.INFIRMARY_SHED
    },
    {
        label: "Transport",
        value: LOCATION_TYPE.TRANSPORT
    },
    {
        label: "General",
        value: LOCATION_TYPE.GENERAL
    },
]
export const ALERT_TYPE = {
    LOW_TEMPERATURE: "low_temperature",
    HIGH_TEMPERATURE: "high_temperature",
    LOW_PHYSICAL_ACTIVITY: "low_physical_activity",
    HIGH_PHYSICAL_ACTIVITY: "high_physical_activity",
    UNRESPONSIVE: "unresponsive",
    CRITICAL: "critical_condition",
    CHECK_TAG: "check_tag"
}
export const ALERT_TYPE_LABEL = {
    [ALERT_TYPE.LOW_TEMPERATURE]: "global.txtLowTemperature",
    [ALERT_TYPE.HIGH_TEMPERATURE]: "global.txtHighTemperature",
    [ALERT_TYPE.LOW_PHYSICAL_ACTIVITY]: "global.txtLowPhysicalActivity",
    [ALERT_TYPE.HIGH_PHYSICAL_ACTIVITY]: "global.txtHighPhysicalActivity",
    [ALERT_TYPE.UNRESPONSIVE]: "global.txtUnresponsive",
    [ALERT_TYPE.CRITICAL]: "global.txtCritical",
    [ALERT_TYPE.CHECK_TAG]: "global.txtCheckTag",
}
export const ACTION_TYPE = {
    OK: "ok",
    RECOVERING: "recovering",
    TO_SICK_PEN: "to_sick_pen",
    OUT_SICK_PEN: "out_sick_pen",
    MEDICATED: "medicated",
    EUTHANIZED: "euthanized",
    DEAD: "dead",
    BEACON_DETACHED: "beacon_detached",
    AUTO_MOVED_TO_SICK_PEN: "auto_moved_to_sick_pen",
}
export const ACTION_TYPE_LABEL = {
    [ACTION_TYPE.OK]: "global.txtCheckedOk",
    [ACTION_TYPE.RECOVERING]: "global.txtRecovering",
    [ACTION_TYPE.TO_SICK_PEN]: "global.txtAddedToSickList",
    [ACTION_TYPE.OUT_SICK_PEN]: "global.txtRemovedFromSickList",
    [ACTION_TYPE.MEDICATED]: "global.txtMedicated",
    [ACTION_TYPE.EUTHANIZED]: "global.txtEuthanized",
    [ACTION_TYPE.DEAD]: "global.txtDead",
    [ACTION_TYPE.BEACON_DETACHED]: "global.txtBeaconDetached",
    [ACTION_TYPE.AUTO_MOVED_TO_SICK_PEN]: "global.txtAutoMovedToSickPen"
}
export const SEX_LABEL = {
    "male": "container.AnimalDetail.txtMale",
    "female": "container.AnimalDetail.txtFemale"
}
export const PIG_TYPE = {
    "sow": "container.AnimalDetail.txtMale",
    "female": "container.AnimalDetail.txtFemale"
}
// Nusery room
export const PEN_ID_IN_NURSERY_ROOM = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13",
    "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"
]
export const ZONE_ID_IN_NURSERY_ROOM_USE_KKM = ["A", "B", "C", "D", "E", "F"]
export const PEN_ID_IN_NURSERY_ROOM_USE_KKM = [
    "12", "13", "11", "14", "10", "15", "9", "16", "8", "17",
    "7", "18", "6", "19", "5", "20", "4", "21", "3", "22",
    "2", "23", "1", "24"
];
export const NURSERY_ROOM_IDENTIFIER_USE_KKM = roomConfig.NURSERY_ROOM_IDENTIFIER_USE_KKM ?
    roomConfig.NURSERY_ROOM_IDENTIFIER_USE_KKM :
    ["nb3---nursery-room-6", "nb3---nursery-room-5", "nb1---nursery-room-1"]
export const SICK_PEN_IN_NURSERY_ROOM = roomConfig.SICK_PEN_IN_NURSERY_ROOM ?
    roomConfig.SICK_PEN_IN_NURSERY_ROOM :
    {
        "nb1---nursery-room-1": "6",
        "nb1---nursery-room-2": "19",
        "nb2---nursery-room-3": "6",
        "nb2---nursery-room-4": "19",
        "nb3---nursery-room-5": "6",
        "nb3---nursery-room-6": "19"
    }
// Grower room
export const PEN_ID_IN_GROWER_ROOM = ["1", "2", "3"]
export const SICK_PEN_IN_GROWER_ROOM = ["A", "B"]
export const LOCATION_OF_SICK_PEN_GROWER_ROOM = {
    "2": "A",
    "8": "B"
}
// Gilt shed
export const PEN_ID_IN_GILT_SHED = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]
// Sow dry room
export const PEN_ID_IN_SOW_DRY_ROOM = roomConfig.PEN_ID_IN_SOW_DRY_ROOM ?
    roomConfig.PEN_ID_IN_SOW_DRY_ROOM :
    {
        "dry-sow-shed-1": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
        "dry-sow-shed-2": ["12", "13", "14", "15", "16", "17", "18", "19", "20"]
    }
export const PEN_ID_IN_SOW_DRY_ROOM_ALL = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20"]

// Sow farrowing room
export const PEN_ID_IN_SOW_FARROWING = [
    "A", "B", "C", "D", "E", "F"
]
export const PEN_ID_POOR_FARROWING_SOW = ["3", "8", "19", "24", "29", "44", "48", "49"]
export const PEN_ID_SUB_OPTIMAL_FARROWING_SOW = ["2", "4", "12", "14", "25", "30"]

export const GENERAL_ROOM_DUMMY_DATA = {
    "thainguyenuni.room_1": ["A", "B"],
    "thainguyenuni.room_2": ["A", "B"],
    "thainguyenuni.room_3": ["A"],
    "msschippers.room_1": ["A"],
    "msschippers.room_2": ["A"],
}
export const ZONE_ID_IN_INFIRMARY = [
    "A", "B", "C", "D", "E", "F", "G"
]
export const ZONE_ID_IN_LOADING_SHED = [
    "A", "B", "C", "D", "E", "F"
]
export const ZONE_ID_IN_OVERFLOW_SHED = [
    "A", "B", "C", "D", "E"
]

export const NUMBER_OF_WEEKS_IN_NURSERY_ROOM = roomConfig.NUMBER_OF_WEEKS_IN_NURSERY_ROOM ? roomConfig.NUMBER_OF_WEEKS_IN_NURSERY_ROOM : 6
export const WOA_BEGIN_IN_NURSERY_ROOM = roomConfig.WOA_BEGIN_IN_NURSERY_ROOM ? roomConfig.WOA_BEGIN_IN_NURSERY_ROOM : 4
export const NUMBER_OF_WEEKS_IN_GROWER_ROOM = roomConfig.NUMBER_OF_WEEKS_IN_GROWER_ROOM ? roomConfig.NUMBER_OF_WEEKS_IN_GROWER_ROOM : 11
export const WOA_BEGIN_IN_GROWER_ROOM = roomConfig.WOA_BEGIN_IN_GROWER_ROOM ? roomConfig.WOA_BEGIN_IN_GROWER_ROOM : 11
export const RELEASE_WEEK_OF_GROWER_ROOM = roomConfig.RELEASE_WEEK_OF_GROWER_ROOM ? roomConfig.RELEASE_WEEK_OF_GROWER_ROOM : 7

export const STATUS_OF_RESOURCE = {
    ACTIVE: "active",
    INACTIVE: "inactive"
}

export const COMPASS_DIRECTION = {
    0: "Xsights_XLM App UX_Compass_North is LEFT.png",
    45: "Xsights_XLM App UX_Compass_North is TopLeft-02.png",
    90: "Xsights_XLM App UX_Compass_North is UP.png",
    135: "Xsights_XLM App UX_Compass_North is TopLeft-08.png",
    180: "Xsights_XLM App UX_Compass_North is REIGH.png",
    225: "Xsights_XLM App UX_Compass_North is BottomRight.png",
    270: "Xsights_XLM App UX_Compass_North is DOWN.png",
    315: "Xsights_XLM App UX_Compass_North is BottomLeft.png",
    360: "Xsights_XLM App UX_Compass_North is LEFT.png",
}
export const TYPE_OF_PIGS = {
    grower_pigs: "grower_pigs",
    sow_pigs: "sow_pigs",
    mix: "mix"
}
export const TYPE_OF_PIGS_LABEL = {
    [TYPE_OF_PIGS.grower_pigs]: "container.RoomViewPage.txtGrowerPigs",
    [TYPE_OF_PIGS.sow_pigs]: "container.RoomViewPage.txtSowPigs",
    [TYPE_OF_PIGS.mix]: "container.RoomViewPage.txtMixedPigs",
}

export const INITIAL_SIGN_IN_FORM = {
    email: '',
    password: '',
}
export const INITIAL_TENANT_SELECTOR_FORM = {
    tenantIdentifier: '',
}
export const INITIAL_RESET_PASSWORD_FORM = {
    password: '',
    confirmPassword: ''
}
export const INITIAL_FORGOT_PASSWORD_FORM = {
    email: '',
}
export const INITIAL_FILTER_TABLE_DATA = {
    page: 1,
    limit: 10,
    order_by: null,
    filter_by: {},
    count: 10,
    search: ''
}
export const INITIAL_LOCATION_FORM = {
    name: '',
    identifier: '',
    description: '',
    parent_id: null,
    type: null,
}
export const INITIAL_PEN_FORM = {
    name: '',
    description: '',
    location_id: null,
    is_sick_pen: false
}
export const INITIAL_ANIMAL_FORM = {
    sow_id: ''
}
export const NON_LIVE_DATA = {
    sex: '-',
    breed: '-',
    sperm: '-',
    birth_weight: '-',
    mother: '-'
}
export const SELECT_TIME_RANGE_OPTIONS = [
    {
        label: "global.txtPast24hours",
        value: 24
    },
    {
        label: "global.txtPast2days",
        value: 48
    },
    {
        label: "global.txtPast7days",
        value: 168
    },
]
export const TAG_TYPE = {
    grower: "grower",
    sow: "sow",
}
export const TAG_TYPE_LABEL = {
    [TAG_TYPE.grower]: "Xiot-G",
    [TAG_TYPE.sow]: "Xiot-S",
}
export const TAG_TYPE_LABEL_SHORTEN = {
    [TAG_TYPE.grower]: "G",
    [TAG_TYPE.sow]: "S",
}
export const EVENT_LOG_TYPE = {
    ALERT_HIGH_TEMPERATURE: "alert_high_temperature",
    ALERT_LOW_TEMPERATURE: "alert_low_temperature",
    ALERT_HIGH_ACTIVITY: "alert_high_activity",
    ALERT_LOW_ACTIVITY: "alert_low_activity",
    OBSERVATION_RECORD_INSPECTED: "observation_record_inspected",
    OBSERVATION_RECORD_OUT_SICK_PEN: "observation_record_out_sick_pen",
    OBSERVATION_RECORD_TO_SICK_PEN: "observation_record_to_sick_pen",
    OBSERVATION_RECORD_MEDICATED: "observation_record_medicated",
    OBSERVATION_RECORD_RECOVERING: "observation_record_recovering",
    OBSERVATION_RECORD_HEALTHY: "observation_record_healthy",
    OBSERVATION_RECORD_REMOVE_FROM_MONITOR_LIST: "observation_record_remove_from_monitor_list",
    TAG_ONBOARDED: "tag_onboarded",
    TAG_OFFBOARDED: "tag_offboarded",
    ROOM_TRANSITION: "room_transition",
    MANAGEMENT_TASK_ADDED: "management_task_added",
    MONITOR_TASK_ADDED: "monitor_task_added",
    AUTO_REMOVED_FROM_MONITOR_LIST: "auto_removed_from_monitor_list",
    STATUS_CHANGED: "status_changed",
    TAG_DEPLOYED: "tag_deployed",
    WEEK_OF_BIRTH: "week_of_birth",
    ALERT_CRITICAL_CONDITION: "alert_critical_condition",
    ALERT_CHECK_TAG: "alert_check_tag",
}

export const EVENT_LOG_TYPE_LABEL = {
    [EVENT_LOG_TYPE.ALERT_HIGH_TEMPERATURE]: "container.AnimalDetail.txtAlertHighTemperature",
    [EVENT_LOG_TYPE.ALERT_LOW_TEMPERATURE]: "container.AnimalDetail.txtAlertLowTemperature",
    [EVENT_LOG_TYPE.ALERT_HIGH_ACTIVITY]: "container.AnimalDetail.txtAlertHighActivity",
    [EVENT_LOG_TYPE.ALERT_LOW_ACTIVITY]: "container.AnimalDetail.txtAlertLowActivity",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_INSPECTED]: "container.AnimalDetail.txtInspected",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_OUT_SICK_PEN]: "container.AnimalDetail.txtRemovedFromSickList",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_TO_SICK_PEN]: "container.AnimalDetail.txtAddedToSickList",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_MEDICATED]: "container.AnimalDetail.txtMedicated",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_RECOVERING]: "container.AnimalDetail.txtSickRecovering",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_HEALTHY]: "container.AnimalDetail.txtCheckedOk",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_REMOVE_FROM_MONITOR_LIST]: "container.AnimalDetail.txtRemoveFromMonitorList",
    [EVENT_LOG_TYPE.TAG_ONBOARDED]: "container.AnimalDetail.txtTagOnboarded",
    [EVENT_LOG_TYPE.TAG_OFFBOARDED]: "container.AnimalDetail.txtTagOffboarded",
    [EVENT_LOG_TYPE.ROOM_TRANSITION]: "container.AnimalDetail.txtRoomTransition",
    [EVENT_LOG_TYPE.MANAGEMENT_TASK_ADDED]: "container.AnimalDetail.txtManagementTaskAdded",
    [EVENT_LOG_TYPE.MONITOR_TASK_ADDED]: "container.AnimalDetail.txtMonitorTaskAdded",
    [EVENT_LOG_TYPE.AUTO_REMOVED_FROM_MONITOR_LIST]: "container.AnimalDetail.txtAutoRemovedFromMonitorList",
    [EVENT_LOG_TYPE.STATUS_CHANGED]: "container.AnimalDetail.txtStatusChanged",
    [EVENT_LOG_TYPE.TAG_DEPLOYED]: "container.AnimalDetail.txtTagDeployed",
    [EVENT_LOG_TYPE.WEEK_OF_BIRTH]: "container.AnimalDetail.txtWeekOfBirth",
    [EVENT_LOG_TYPE.ALERT_CRITICAL_CONDITION]: "container.AnimalDetail.txtCritical",
    [EVENT_LOG_TYPE.ALERT_CHECK_TAG]: "container.AnimalDetail.txtCheckTag",
}
export const EVENT_LOG_TYPE_ICON = {
    [EVENT_LOG_TYPE.ALERT_HIGH_TEMPERATURE]: "high-temerature.png",
    [EVENT_LOG_TYPE.ALERT_LOW_TEMPERATURE]: "low-temerature.png",
    [EVENT_LOG_TYPE.ALERT_HIGH_ACTIVITY]: "high-physical-activity.png",
    [EVENT_LOG_TYPE.ALERT_LOW_ACTIVITY]: "low-physical-activity.png",
    [EVENT_LOG_TYPE.ROOM_TRANSITION]: "room-transition.png",
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_INSPECTED]: null,
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_OUT_SICK_PEN]: null,
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_TO_SICK_PEN]: null,
    [EVENT_LOG_TYPE.OBSERVATION_RECORD_MEDICATED]: null,
    [EVENT_LOG_TYPE.TAG_DEPLOYED]: null,
    [EVENT_LOG_TYPE.WEEK_OF_BIRTH]: null,
    [EVENT_LOG_TYPE.ALERT_CRITICAL_CONDITION]: "critical-alert.png",
    [EVENT_LOG_TYPE.ALERT_CHECK_TAG]: "check-tag.png",
}
export const DAILY_TASK_TYPE = {
    MANAGEMENT_TASK: "management_task",
    MONITOR_TASK: "monitor_task"
}
export const DAILY_TASK_SOURCE = {
    MANUAL: "manual",
    GENERATOR: "generator",
    USER_ADDED: "user_added"
}
export const CPFOODS_G4_CUSTOM_LAYOUT = "cpfoods.room_g4"