import ApiService from './ApiService'
import API from 'configs/apis'

export async function apiGetSickPen(data) {
    const result = await ApiService.fetchData({
        url: API.getAnimals,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetAnimalRecentAlerts(data) {
    const result = await ApiService.fetchData({
        url: API.getAnimals,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetAnimalAlerts(data) {
    const result = await ApiService.fetchData({
        url: API.getAnimalAlerts,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetAnimalAlertsV2(data) {
    const result = await ApiService.fetchData({
        url: API.getAnimalAlertsV2,
        method: 'get',
        params: data
    })
    return result
}

export async function apiGetEventLog(data) {
    const result = await ApiService.fetchData({
        url: API.getEventLog,
        method: 'get',
        params: data
    })
    return result
}