import { createSlice } from '@reduxjs/toolkit'
import { ANIMAL_MANAGEMENT_PAGE_TAB_VALUE } from 'constants/app.constant'

const stateSlice = createSlice({
    name: 'location/state',
    initialState: {
        deleteConfirmation: false,
        selectedLocation: '',
        isOpenDialog: false,
        isOpenAnimalAddEdit: false,
        currentTabContent: ANIMAL_MANAGEMENT_PAGE_TAB_VALUE.ROOM_INVENTORY
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload
        },
        setOpenDialog: (state, action) => {
            state.isOpenDialog = action.payload
        },
        setOpenAnimalAddEdit: (state, action) => {
            state.isOpenAnimalAddEdit = action.payload
        },
        setCurrentTabContent: (state, action) => {
            state.currentTabContent = action.payload
        },
    },
})

export const {
    toggleDeleteConfirmation,
    setSelectedLocation,
    setOpenDialog,
    setOpenAnimalAddEdit,
    setCurrentTabContent,
} = stateSlice.actions

export default stateSlice.reducer
